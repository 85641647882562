import { addFloorPlanToCache } from '../selectors/addFloorPlanToCache';
import { getFloorPlanFromCache } from '../selectors/getFloorPlanFromCache';
import useFloorPlan from 'hybridspace-common/lib/serviceHooks/useFloorPlan';
import {
    PlacesMapsLoadE2ECustomData,
    PlacesMapsLoadE2ECheckmark,
} from 'hybridspace-performance-datapoints';
import React from 'react';
import sha256 from 'hash.js/lib/hash/sha/256';

import type { Floor, FloorPlan } from 'hybridspace-common/lib/serviceTypes';
import type { PlacesMapsTableEntry } from '../database';

/**
 * Custom hook to fetch map data from IndexedDB first:
 * - If no data is found in IndexedDB, make a network call to fetch map data and store it in IndexedDB.
 * - If data is found in IndexedDB, return it immediately, then make a network call to check if the data is up to date,
 *  and re-render if map from cache is obsolete.
 *
 * TODO: Replace hash check on client with Etag check, once GetMapFeatures API supports Etag.
 * WI on Etag support: https://outlookweb.visualstudio.com/MicrosoftPlaces/_workitems/edit/294573
 */
export default function useFloorPlanWithCache(
    floorId: string | null,
    skipTelemetry: boolean = false
) {
    // An empty floorPlan to return when data is still loading.
    const emptyFloorPlan = getEmptyFloorPlan();

    // Flag to make sure network call is only made if cache fetch is finished and there's no data found.
    const [cacheFetchInProgress, setCacheFetchInProgress] = React.useState(false);
    const [floorFromCache, setFloorFromCache] = React.useState<Floor>(emptyFloorPlan);
    // Keep track of prevFloorId to avoid update cache prematurely.
    const [prevFloorId, setPrevFloorId] = React.useState<string | null>(null);
    // Flag to show if the final result is from Cache or Network fetch.
    const [isFromCache, setIsFromCache] = React.useState<boolean>(true);

    /**
     * When floorId changes, first reset cache flag & clear map data,
     * then try to fetch map for the new floor from IndexedDB.
     */
    React.useEffect(() => {
        if (!floorId) {
            return;
        }

        setPrevFloorId(floorId);
        setFloorFromCache(emptyFloorPlan);
        setCacheFetchInProgress(true);

        getFloorPlanFromCache(floorId).then((result: PlacesMapsTableEntry | undefined) => {
            if (!!result && !!result.floor) {
                if (!skipTelemetry) {
                    PlacesMapsLoadE2ECustomData({ inIDB: true });
                }

                // Only update floorFromCache if there's no valid map for this floorId.
                if (!isFloorDataValid(floorFromCache, floorId)) {
                    setFloorFromCache(result.floor);
                }
            }

            if (!skipTelemetry) {
                PlacesMapsLoadE2ECheckmark('idbe' /* IndexedDB fetch ends */);
            }

            setCacheFetchInProgress(false);
        });
    }, [floorId]);

    // While cache fetch is in progress, make a network call to GetMapFeatures API to
    // get the most up-to-date map data.
    const {
        floor: floorFromNetwork,
        loading: networkLoading,
        error,
    } = useFloorPlan(
        floorId,
        skipTelemetry /** skipTelemetry */,
        !floorId || floorId !== prevFloorId
    ); /** skip */

    /**
     * When map is fetched from network request, compare it with cached map.
     * If cached map is obsolete, replace it with map from network and re-render.
     */
    React.useEffect(() => {
        if (!floorId) {
            return;
        }

        // When a valid floor data is fetched from network request
        if (isFloorDataValid(floorFromNetwork, floorId)) {
            const floorFromCacheHash = computeFloorPlanHash(floorFromCache.floorPlan);
            const floorFromNetworkHash = computeFloorPlanHash(floorFromNetwork.floorPlan);

            // If there's no cached map or cached map is obsolete,
            // update cache and return map from network.
            if (
                !isFloorDataValid(floorFromCache, floorId) ||
                floorFromCacheHash !== floorFromNetworkHash
            ) {
                addFloorPlanToCache(floorFromNetwork);
                setFloorFromCache(floorFromNetwork);
                setIsFromCache(false);

                if (!skipTelemetry) {
                    PlacesMapsLoadE2ECustomData({ updateCache: true });
                }
            }
        }
    }, [floorFromNetwork, floorFromCache, setFloorFromCache, floorId, setIsFromCache]);

    const loadingState = {
        floor: emptyFloorPlan,
        loading: true,
        error: undefined,
        isFromCache: undefined,
    };
    const errorState = { floor: emptyFloorPlan, loading: false, error, isFromCache: undefined };
    const emptyState = {
        floor: emptyFloorPlan,
        loading: false,
        error: undefined,
        isFromCache: undefined,
    };

    if (!floorId) {
        return emptyState;
    }

    if (error) {
        return errorState;
    }

    // There's a valid map from either IndexedDB or network, return it
    if (isFloorDataValid(floorFromCache, floorId)) {
        return { floor: floorFromCache, loading: false, error: undefined, isFromCache };
    }

    // When both cache fetch and network request are finished and no valid map is found,
    // return empty state.
    if (
        !cacheFetchInProgress &&
        !networkLoading &&
        !isFloorDataValid(floorFromCache, floorId) &&
        !isFloorDataValid(floorFromNetwork, floorId) &&
        floorId === prevFloorId
    ) {
        return emptyState;
    }

    // All other cases, return loading state.
    return loadingState;
}

// Check if floor object exists and contains a valid floor plan.
function isFloorDataValid(floor: Floor | undefined, targetFloorId: string) {
    return !!floor && floor.floorId === targetFloorId && hasFloorPlan(floor.floorPlan);
}

function hasFloorPlan(floorPlan: Map<string, any>) {
    return floorPlan && floorPlan.size > 0;
}

function getEmptyFloorPlan(): Floor {
    return {
        floorId: null,
        floorPlan: new Map(),
        displayCenter: { lat: 0, lng: 0 },
    };
}

function stringifyFloorPlan(floorPlan: FloorPlan) {
    const outerArray = Array.from(floorPlan.entries()).map(([key, innerMap]) => {
        const innerArray = Array.from(innerMap.entries());
        return [key, innerArray];
    });

    return JSON.stringify(outerArray);
}

function computeFloorPlanHash(floorPlan: FloorPlan) {
    return sha256().update(stringifyFloorPlan(floorPlan)).digest('hex');
}
