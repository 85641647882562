import { getNavigationState } from 'accelerator-router';
import { lazyLoadWorkspaceReservations } from 'hybridspace-desk-booking';
import { createDateRange } from 'owa-datetime-utils';
import { isGuid } from 'owa-guid';
import { type UTPageType } from 'owa-unified-telemetry';
import { lazyPlacesBuildingModule } from 'places-explore';
import {
    getCurrentDate,
    getCurrentPlace,
    getDefaultCurrentPlace,
    getWorkLocationInfo,
    setRecentBuildingsFromCache,
} from 'places-explore-app-store';
import {
    loadCollaboratorsLocations,
    loadFWH,
    loadPeopleInMeetings,
    loadPlaces,
    loadPlacesAsync,
    loadWorkLocationInfoAsync,
} from 'places-explore-operations';
import { placesFwkPageLoadAction } from 'places-fwk-actions';
import { getPlaces, setTenantHasBuildingsCacheValue } from 'places-place-store';
import { redirect } from 'react-router-dom';
import { getTenantBuildingsExist } from './sharedLoaderUtils/getTenantBuildingsExist';

import type { LoaderFunction } from 'react-router-dom';

// Loader for the homepage route (/place)
// we only need to define a loader if the explore page feature flag is enabled
// TODO, we need to remove the explore page feature flag check once the feature is enabled for all users
export const placesHomeRouteLayoutLoader: LoaderFunction = async () => {
    placesFwkPageLoadAction('Explore', 'start', 'Started');

    // Check if the tenant has places, if not return null
    setTenantHasBuildingsCacheValue();
    const tenantHasPlaces = await getTenantBuildingsExist();
    if (!tenantHasPlaces) {
        return null;
    }

    //We set the buildings from the cache
    setRecentBuildingsFromCache();

    // If the origin is a logo click, we will reset their
    // current building to the one from FWH, if it exists
    const navigationState = getNavigationState();
    const origin = navigationState?.origin;
    if (origin && origin === 'PlacesLogo') {
        const workLocationInfo = getWorkLocationInfo();
        if (workLocationInfo?.place && isGuid(workLocationInfo.place.id)) {
            return setBuildingId(workLocationInfo.place.id);
        }
    }

    // Skip critical data
    // 1. If we have a default place, we will skip
    // 2. If we have places AND have checked FWH , we will skip
    let buildingId = getCurrentPlace()?.id ?? getDefaultCurrentPlace();
    if (buildingId || (getPlaces().size > 0 && getWorkLocationInfo())) {
        if (buildingId) {
            return setBuildingId(buildingId);
        } else {
            return null;
        }
    }

    /**
     * Define & Execute noncritical data: reservations, places, collaborators locations,
     * people in meetings
     */
    const todayDateRange = createDateRange(getCurrentDate(), 1);
    loadFWH(todayDateRange);

    lazyLoadWorkspaceReservations.import().then(loadWorkspaceReservations => {
        loadWorkspaceReservations(getCurrentDate());
    });

    loadPlaces();
    loadCollaboratorsLocations(todayDateRange);
    loadPeopleInMeetings(todayDateRange, todayDateRange.start);

    // Define critical data: places building module and load places
    const criticalData: Promise<any>[] = [lazyPlacesBuildingModule.import(), loadPlacesAsync()];

    //If we don't have a current building ID, we will try to grab it from FWH or the cache
    if (!buildingId) {
        try {
            const res = await loadWorkLocationInfoAsync(todayDateRange);
            if (res.place && isGuid(res.place.id)) {
                return setBuildingId(res.place.id);
            } else {
                throw new Error('No place found');
            }
        } catch (e) {
            //Try to get from the cache
            buildingId = getDefaultCurrentPlace();
        }
    }

    //If we do have one, we will redirect to the building page, and await the promise of critical data, in this case the lazy loaded component
    if (buildingId && isGuid(buildingId)) {
        return setBuildingId(buildingId);
    }

    // If no building id is found, we will await the critical data
    return Promise.all(criticalData);
};

function setBuildingId(buildingId: string): Response | null {
    if (!isGuid(buildingId)) {
        return null;
    }

    return redirect(`/buildings/${buildingId}`);
}
