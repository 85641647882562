import { AccAppHeader } from 'accelerator-app-header';
import { HybridspaceDiagnostics } from 'hybridspace-diagnostics';
import { deletePlacesMapsCache } from 'hybridspace-map-cache';
import { usePlacesDashboardLoadE2ECheckpoint } from 'hybridspace-performance-datapoints';
import { lazyDeletePlacesDatabase } from 'hybridspace-persistent-store';
import microsoftPlacesLogo from 'owa-appbar-icons/lib/microsoftPlaces.svg';
import { getApplicationSettings } from 'owa-application-settings';
import { getLogicalRing } from 'owa-config';
import { isFeatureEnabled } from 'owa-feature-flags';
import strings from 'owa-locstrings/lib/strings/appMicrosoftPlaces.locstring.json';
import { observer } from 'owa-mobx-react';
import { PLACES_APP_NAME, PLACES_BRANDING_NAME } from 'places-common-strings';
import { deletePlacesPeopleCache } from 'places-people-store';
import { deleteTenantPlacesCache } from 'places-place-store';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

function onSignoutClick() {
    lazyDeletePlacesDatabase.importAndExecute();
    deletePlacesPeopleCache();
    deletePlacesMapsCache();
    deleteTenantPlacesCache();
}

export default observer(function HybridspaceSuiteHeader({ compact }: { compact: boolean }) {
    const navigate = useNavigate();
    const navigateHome = useCallback(() => navigate('/'), [navigate]);
    // Enable smiley feedback for SDF users and OCV feedback for non-SDF users
    const isSdfUser = getLogicalRing() === 'Dogfood';
    const isSmileyFeedbackEnabled = isSdfUser || isFeatureEnabled('msplaces-dev-smileyFeedback');
    const isOCVEnabled = !isSdfUser || isFeatureEnabled('msplaces-dev-OCVFeedback');
    usePlacesDashboardLoadE2ECheckpoint(true /** successCondition */, 'nhm');

    const isDiagnosticsEnabled = getApplicationSettings('Diagnostics').panel;

    return (
        <AccAppHeader
            appId="Hybridspace"
            appIconSvg={microsoftPlacesLogo}
            appLogoSvg={microsoftPlacesLogo}
            appNameResourceId={strings.appMicrosoftPlaces}
            appNameString={PLACES_APP_NAME}
            appShortNameResourceId={strings.hybridspacePlacesTitle}
            appShortNameString={PLACES_BRANDING_NAME}
            appStatus={compact ? undefined : 'preview'}
            onAppNameClick={navigateHome}
            DiagnosticPanel={isDiagnosticsEnabled ? HybridspaceDiagnostics : undefined}
            onSignOut={onSignoutClick}
            showOCV={isOCVEnabled}
            showFeedback={isSmileyFeedbackEnabled}
            theme={'neutral'}
            layout={'persona'}
        />
    );
}, 'HybridspaceSuiteHeader');
