import { markCalendarEntryAsValid, updateCalendarEntry } from '../actions/publicActions';
import type { CalendarEntry } from 'owa-graph-schema';
import {
    getCalendarEntryByCalendarId,
    getDefaultCalendar,
} from '../selectors/calendarsCacheSelectors';
import getStore from '../store/store';
import { mutator, mutatorAction } from 'satcheljs';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(updateCalendarEntry, actionMessage => {
    const { id, calendarEntry } = actionMessage;

    const calendarEntryInStore = getCalendarEntryByCalendarId(id);
    if (!calendarEntryInStore) {
        return;
    }

    const userIdentity = calendarEntryInStore.calendarId.mailboxInfo?.userIdentity;
    const defaultCalendar = getDefaultCalendar(userIdentity);
    const shouldUpdateDefaultCalendar =
        defaultCalendar && defaultCalendar.calendarId.id === calendarEntryInStore.calendarId.id;

    const { calendarId, ...calendarEntryWithoutItemId } = calendarEntry;

    Object.assign(calendarEntryInStore, calendarEntryWithoutItemId);
    if (shouldUpdateDefaultCalendar) {
        Object.assign(defaultCalendar, calendarEntryWithoutItemId);
    }
});

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(markCalendarEntryAsValid, actionMessage => {
    const { calendarEntry } = actionMessage;
    const store = getStore();

    store.validEntryMapping.set(calendarEntry.calendarId.id, true);
});

export const setCalendarEntryMappingForId = mutatorAction(
    'setCalendarEntryMappingForId',
    (calendarId: string, calendarEntry: CalendarEntry) => {
        getStore().calendarEntryMapping.set(calendarId, calendarEntry);
    }
);
