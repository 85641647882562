import loc, { isCurrentCultureRightToLeft } from 'owa-localize';
import { observer } from 'owa-mobx-react';
import getPluralizedString from 'owa-plural/lib/getPluralizedString';
import React from 'react';
import { ImageFit } from '@fluentui/react';
import {
    Button,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    mergeClasses,
    Tab,
    TabList,
    Tooltip,
} from '@fluentui/react-components';
import { bundleIcon } from '@fluentui/react-icons';
import {
    Overflow,
    OverflowItem,
    useIsOverflowItemVisible,
    useOverflowMenu,
} from '@fluentui/react-overflow';
import { Icon, ImageIcon } from '@fluentui/react/lib/Icon';
import { getValue, useAccItems } from './AccItem';
import strings from './AccTabList.locstring.json';
import {
    accTab,
    accTabContent,
    accTabContentResponsive,
    accTabList,
    accTabListResponsive,
    iconStyle,
    moreButtonIcon,
    overflowButton,
    overflowButtonWithText,
    toolTipStyle,
} from './AccTabList.scss';
import createFluentIcon from './createFluentIcon';

import type { SelectTabData, PositioningShorthand } from '@fluentui/react-components';
import type { AccItem } from './AccItem';
import type { ResourceId } from 'owa-localize';
const MoreHorizontalFilled = createFluentIcon('AccMoreHorizontalFilled', '1em', [
    'M6.75 10a1.75 1.75 0 1 1-3.5 0 1.75 1.75 0 0 1 3.5 0Zm5 0a1.75 1.75 0 1 1-3.5 0 1.75 1.75 0 0 1 3.5 0ZM15 11.75a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5Z',
]);

const MoreHorizontalRegular = createFluentIcon('AccMoreHorizontalRegular', '1em', [
    'M6.25 10a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0Zm5 0a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0ZM15 11.25a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z',
]);

const AppFolderFilled = createFluentIcon('AccAppFolderFilled', '1em', [
    'M5 6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V6Zm0 6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-2Zm6-6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V6Zm0 6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2ZM2 5a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5Zm3-2a2 2 0 0 0-2 2v10c0 1.1.9 2 2 2h10a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Z',
]);

const AppFolderRegular = createFluentIcon('AccAppFolderRegular', '1em', [
    'M5 6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V6Zm3 0H6v2h2V6Zm-3 6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-2Zm3 0H6v2h2v-2Zm4-7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1h-2Zm0 1h2v2h-2V6Zm-1 6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2Zm3 0h-2v2h2v-2ZM5 2a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3H5ZM3 5c0-1.1.9-2 2-2h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5Z',
]);

/**
 * A tab item for AccTabList.
 *
 * The `id` is used to identify DOM elements associated with the tab and for telemetry purposes.
 * The id of a tab should be relatively static between instances of a component. For example,
 * in a list of tabs that represent dates of a week view, the id might represent the weekday
 * of a date, so even if we change the week being shown, the id of of the tab for "Monday" stays
 * the same.
 *
 * The `key` is used to select a particular tab, and the value can change in different runs of
 * the application. In the example above, the key would be the ISO value of the date represented
 * by the tab, making it easy for the application to select a particular tab by date and to track
 * the selected date from the selected key.
 */
export interface AccTabItem extends Omit<AccItem, 'textResourceId'> {
    id: string;
    text?: string | (() => string);
    textResourceId?: ResourceId | (() => ResourceId);
    activeIcon?: string;
    activeIconRTL?: string;
    iconStyle?: string;
    activeIconStyle?: string;
    /**
     * Properties to load an image.
     *
     * Note: image takes precedence over icons when they're both present. This is the same handling that FluentUI Icon element has.
     */
    image?: AccTabImageProps;
    tooltip?: string;
}

export interface AccTabImageProps {
    src: string;
    className?: string;
}

/**
 * A TabList with an overflow menu.
 */
export const AccTabList = observer(function AccTabList({
    className,
    items,
    selectedKey,
    onSelectedKeyChange,
    disabled,
    minimumVisible = 0,
    appearance = 'transparent',
    showActiveIcon = true,
    isVertical,
    responsiveTabs, // When in medium size screen only show Icons for tabs
    isAppFolderOverflowIcon, // Use AppFolder icon with text for the overflow button
    size,
    tooltipPosition,
}: {
    className?: string;
    items: AccTabItem[];
    selectedKey: string;
    onSelectedKeyChange: (newSelectedKey: string) => void;
    disabled?: boolean;
    minimumVisible?: number;
    appearance?: 'transparent' | 'subtle';
    showActiveIcon?: boolean;
    isVertical?: boolean;
    responsiveTabs?: boolean;
    isAppFolderOverflowIcon?: boolean;
    size?: 'small' | 'medium' | 'large';
    tooltipPosition?: PositioningShorthand;
}) {
    // Please update the following 'any' type(s) to be accurate to usage
    const onTabSelect = React.useCallback(
        (_: any, data: SelectTabData) => {
            onSelectedKeyChange(data.value as string);
        },
        [onSelectedKeyChange]
    );
    const positioning = tooltipPosition ?? (isVertical ? 'after' : undefined);

    const tabItems = useAccItems(items, item => (
        <AccTabItemAsTab
            key={item.key}
            item={item}
            selected={item.key === selectedKey}
            showActiveIcon={showActiveIcon}
            responsiveTabs={responsiveTabs}
            positioning={positioning}
        />
    ));

    const menuItems = useAccItems(items, item => (
        <AccTabItemAsMenuItem
            key={item.key}
            item={item}
            selected={item.key === selectedKey}
            onSelectedKeyChange={onSelectedKeyChange}
            showActiveIcon={showActiveIcon}
        />
    ));

    const tabListDataId = 'TabList';

    return isVertical ? (
        <div className={className ?? accTabList}>
            <TabList
                onTabSelect={onTabSelect}
                selectedValue={selectedKey}
                disabled={disabled}
                appearance={appearance}
                vertical={true}
                data-telemetry-id={tabListDataId}
                size={size}
            >
                {tabItems}
            </TabList>
        </div>
    ) : (
        <div
            className={mergeClasses(responsiveTabs && accTabListResponsive, accTabList, className)}
        >
            <Overflow minimumVisible={minimumVisible}>
                <TabList
                    onTabSelect={onTabSelect}
                    selectedValue={selectedKey}
                    disabled={disabled}
                    appearance={appearance}
                    data-telemetry-id={tabListDataId}
                    size={size}
                >
                    {tabItems}
                    <AccTabOverflowMenu isAppFolderOverflowIcon={isAppFolderOverflowIcon}>
                        {menuItems}
                    </AccTabOverflowMenu>
                </TabList>
            </Overflow>
        </div>
    );
}, 'AccTabList');

function useAccTabItem(item: AccTabItem, selected: boolean, showActiveIcon?: boolean) {
    const text = getValue(item.text);
    const textResourceIdValue = getValue(item.textResourceId);
    const content = text || (textResourceIdValue ? loc(textResourceIdValue) : undefined);
    const ariaLabel = item.ariaLabelResourceId
        ? loc(item.ariaLabelResourceId)
        : text
        ? text
        : item.tooltip;
    const disabled = getValue(item.disabled);
    const tooltip = getValue(item.tooltip);

    const isRtl = isCurrentCultureRightToLeft();
    const normalIconLTR = item.icon;
    const normalIconRTL = item.iconRTL;
    const activeIconLTR = item.activeIcon;
    const activeIconRTL = item.activeIconRTL;
    const normalIcon = normalIconRTL && isRtl ? normalIconRTL : normalIconLTR;
    const activeIcon = activeIconRTL && isRtl ? activeIconRTL : activeIconLTR;
    const showIconAsSelected = selected && showActiveIcon;
    const iconToUse = showIconAsSelected ? activeIcon : normalIcon;
    const itemIconStyleToUse = showIconAsSelected ? item.activeIconStyle : item.iconStyle;

    const imageProps = React.useMemo(
        () =>
            item.image?.src
                ? {
                      src: item.image.src,
                      className: item.image?.className,
                      imageFit: ImageFit.cover,
                  }
                : undefined,
        [item.image?.src, item.image?.className]
    );

    const icon = React.useMemo(() => {
        if (imageProps) {
            // ImageIcon instead of regular Icon as recommended by FluentUI's Icon element.
            return <ImageIcon imageProps={imageProps} />;
        }

        if (iconToUse) {
            return (
                <Icon
                    iconName={iconToUse}
                    className={mergeClasses(iconStyle, itemIconStyleToUse)}
                />
            );
        }

        return undefined;
    }, [iconToUse, imageProps, itemIconStyleToUse]);
    return {
        content,
        ariaLabel,
        disabled,
        icon,
        tooltip,
    };
}

/**
 * AccTabItem rendered as a Tab, for the TabList.
 */
const AccTabItemAsTab = observer(function AccTabItemAsTab({
    item,
    selected,
    showActiveIcon,
    responsiveTabs,
    positioning,
}: {
    item: AccTabItem;
    selected: boolean;
    showActiveIcon?: boolean;
    responsiveTabs?: boolean;
    positioning?: PositioningShorthand;
}) {
    const { content, ariaLabel, disabled, icon, tooltip } = useAccTabItem(
        item,
        selected,
        showActiveIcon
    );

    const actualContent = React.useMemo(
        () =>
            content != undefined
                ? {
                      className: responsiveTabs ? accTabContentResponsive : accTabContent,
                      children: content,
                  }
                : undefined,
        [content, responsiveTabs]
    );

    const toolTipContent = React.useMemo(
        () => ({ className: toolTipStyle, children: content }),
        [content]
    );

    return (
        <OverflowItem id={item.key} priority={selected ? 1 : undefined}>
            <Tooltip
                content={tooltip ?? toolTipContent}
                relationship="label"
                positioning={positioning}
            >
                <Tab
                    className={accTab}
                    aria-label={ariaLabel}
                    content={actualContent}
                    disabled={disabled}
                    icon={icon}
                    data-telemetry-id={item.id}
                    value={item.key}
                />
            </Tooltip>
        </OverflowItem>
    );
},
'AccTabItemAsTab');

/**
 * AccTabItem rendered as a MenuItem, for the overflow menu.
 * This renders the menu item only if the corresponding tab is is not visible.
 */
const AccTabItemAsMenuItem = observer(function AccTabItemAsMenuItem({
    item,
    selected,
    onSelectedKeyChange,
    showActiveIcon,
}: {
    item: AccTabItem;
    selected: boolean;
    onSelectedKeyChange: (newSelectedKey: string) => void;
    showActiveIcon?: boolean;
}) {
    const { content, ariaLabel, disabled, icon } = useAccTabItem(item, selected, showActiveIcon);
    const tabItemIsVisible = useIsOverflowItemVisible(item.key);

    const onClick = React.useCallback(() => {
        onSelectedKeyChange(item.key);
    }, [onSelectedKeyChange, item.key]);

    return tabItemIsVisible ? null : (
        <MenuItem
            aria-label={ariaLabel}
            content={content}
            disabled={disabled}
            icon={icon}
            onClick={onClick}
            data-telemetry-id={item.id}
        />
    );
},
'AccTabItemAsMenuItem');

const MoreHorizontal = bundleIcon(MoreHorizontalFilled, MoreHorizontalRegular);
const MoreHorizontalElement = <MoreHorizontal />;

const AppFolder = bundleIcon(AppFolderFilled, AppFolderRegular);
const AppFolderElement = <AppFolder />;

/**
 * Overflow Menu of the AccTabList.
 *
 * NOTE: the call to useOverflowMenu CANNOT happen in the same component that renders <Overflow />
 * otherwise isOverflowing comes as false and we don't render the menu.
 * We can also know the overflowCount from this call, in case we want to use a tooltip/aria label.
 *
 * Element data-* attributes used for telementry scoping.
 */
const AccTabOverflowMenu = observer(function AccTabOverflowMenu({
    isAppFolderOverflowIcon,
    children,
}: {
    isAppFolderOverflowIcon?: boolean;
    children: JSX.Element[];
}) {
    const { ref, isOverflowing, overflowCount } = useOverflowMenu<HTMLButtonElement>();
    const tooltip = getPluralizedString(
        overflowCount,
        '',
        strings.accTabListOverflowCountSingular,
        strings.accTabListOverflowCountFirstPlural,
        strings.accTabListOverflowCountSecondGenitivePlural,
        overflowCount
    );

    const iconOverflow = React.useMemo(
        () => ({ className: moreButtonIcon, children: AppFolderElement }),
        []
    );

    return isOverflowing ? (
        <Menu>
            <Tooltip relationship="description" content={tooltip}>
                <MenuTrigger disableButtonEnhancement={true}>
                    <Button
                        ref={ref}
                        role="tab"
                        className={overflowButton}
                        appearance={isAppFolderOverflowIcon ? 'subtle' : 'transparent'}
                        icon={isAppFolderOverflowIcon ? iconOverflow : MoreHorizontalElement}
                        aria-label={tooltip}
                        data-telemetry-id="OverflowMenu"
                    >
                        {isAppFolderOverflowIcon && (
                            <span className={overflowButtonWithText}>
                                {loc(strings.accTabListOverflowTabTitle)}
                            </span>
                        )}
                    </Button>
                </MenuTrigger>
            </Tooltip>
            <MenuPopover>
                <MenuList
                    data-telemetry-id="OverflowMenuList"
                    data-telemetry-parentid="OverflowMenu"
                >
                    {children}
                </MenuList>
            </MenuPopover>
        </Menu>
    ) : null;
},
'AccTabOverflowMenu');
