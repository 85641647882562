import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';
import { createAccessorsForMapStore } from 'owa-viewstate-store';
import { type PlacesFinderLocationTypeFilter } from './schema/PlacesFinderViewState';

import type PlacesFinderViewState from './schema/PlacesFinderViewState';

export const getStore = createStore<{
    viewStates: ObservableMap<string, PlacesFinderViewState>;
}>('hybridspace-places-finder', {
    viewStates: new ObservableMap<string, PlacesFinderViewState>(),
});

export const {
    getSections,
    setSections,
    getSuggestions,
    setSuggestions,
    getSuggestionsSource,
    setSuggestionsSource,
    getSelectedBuildingId,
    setSelectedBuildingId,
    getSelectedFloorId,
    setSelectedFloorId,
    getSelectedCapabilities,
    setSelectedCapabilities,
    getSelectedCapacity,
    setSelectedCapacity,
    getSelectedRoom,
    setSelectedRoom,
    setIsLoading,
    getIsLoading,
    getIsHostedInPlaces,
    setIsHostedInPlaces,
    getSelectedSection,
    setSelectedSection,
    getSelectedLocationType,
    setSelectedLocationType,
} = createAccessorsForMapStore(
    'hybridspace-places-finder',
    () => getStore().viewStates,
    getInitViewStateValue
);

export function getInitViewStateValue(defaultBuildingId?: string): PlacesFinderViewState {
    return {
        sections: [],
        suggestions: [],
        suggestionsSource: 'none',
        selectedBuildingId: defaultBuildingId ?? 'none',
        selectedFloorId: 'none',
        selectedCapabilities: [],
        selectedCapacity: null,
        selectedRoom: null,
        isLoading: true,
        isHostedInPlaces: false,
        selectedSection: null,
        selectedLocationType: 'All',
    };
}
