import { orchestrator } from 'satcheljs';

import { loadPlaceSucceeded } from '../operations/loadPlace';
import { setPlace } from '../store';

/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(loadPlaceSucceeded, ({ result }) => {
    setPlace(result.id, result);
});
