import { createLazyComponent, LazyImport, LazyModule } from 'owa-bundling';
import { Loading, NetworkError } from 'owa-interstitial';

import type { ComponentProps } from 'react';
import type { LazyModuleType } from 'owa-bundling';
import type { MergedWorkGroupsModule as MergedWorkGroupsModuleType } from './lazyIndex';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PlacesWorkGroupsPeopleMerged" */ './lazyIndex')
);

export const MergedWorkGroupsModule = createLazyComponent<
    ComponentProps<typeof MergedWorkGroupsModuleType>,
    LazyModuleType<typeof lazyModule>,
    any
>(lazyModule, m => m.MergedWorkGroupsModule, Loading, NetworkError);

export const lazyPlacesWorkGroupsPeopleMergedModule = new LazyImport(lazyModule, m => m);
