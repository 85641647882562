import { HybridspaceError } from 'hybridspace-common/lib/components/HybridspaceError';
import { scrubForPii } from 'owa-config';
import { type HttpStatusCode } from 'owa-http-status-codes';
import { observer } from 'owa-mobx-react';
import { placesFwkPageLoadAction } from 'places-fwk-actions';
import React from 'react';
import {
    isRouteErrorResponse,
    useNavigate,
    useRevalidator,
    useRouteError,
    useNavigation,
} from 'react-router-dom';
import { type UTPageLoadStatus, type UTPageType } from 'owa-unified-telemetry';
import {
    goHomeButtonLabel,
    notFoundMessage,
    retryActionButtonLabel,
    retryMessage,
} from './PlacesErrorBoundary.locstring.json';
import { container } from './PlacesErrorBoundary.scss';

/**
 * PlacesErrorBoundary page for when the loader fails, Specific cases are set for NotFound and InternalServerError
 * wiki: https://dev.azure.com/outlookweb/MicrosoftPlaces/_wiki/wikis/MicrosoftPlaces.wiki/13460/How-To-Add-an-error-component
 */
export default observer(function PlacesErrorBoundary({
    errorName,
    errorStatus,
}: {
    errorName?: string;
    errorStatus?: HttpStatusCode;
}) {
    const revalidator = useRevalidator();
    const navigate = useNavigate();
    const navigation = useNavigation();

    const error = useRouteError() as any;

    const status = !!errorStatus ? errorStatus : isRouteErrorResponse(error) ? error?.status : 500;

    const message = status === 404 ? notFoundMessage : retryMessage;

    const buttonTitle = status === 404 ? goHomeButtonLabel : retryActionButtonLabel;

    const reload = React.useCallback(() => {
        switch (status) {
            // If not found, navigate to the home page
            case 404:
                navigate('/');
                break;
            // Else we revalidate the route
            default:
                revalidator.revalidate();
                break;
        }
    }, [revalidator, navigate, status]);

    // Add the log for your component
    React.useEffect(() => {
        // when error exists, this will internally will log once.
        if (errorName && errorName === 'PlacesExplorePageRoot') {
            const errorMessage = error?.data ? scrubForPii(error?.data) : undefined;
            placesFwkPageLoadAction(
                'Explore',
                'end',
                status === 404 ? 'NotFound' : 'Error',
                errorMessage
            );
        }
    }, [status, errorName, error?.data]);

    const isLoading = navigation.state === 'loading' || revalidator.state != 'idle';

    return (
        <div className={container}>
            <HybridspaceError
                retry={reload}
                errorMessage={message}
                buttonLabel={buttonTitle}
                brandedButtonStyle={true}
                loading={isLoading}
            />
        </div>
    );
},
'PlacesErrorBoundary');
