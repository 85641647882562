import { placesFwkBootStartAction } from 'hybridspace-performance-datapoints';
import { initializePlacesFinderStore } from 'hybridspace-places-finder-store';
import getGlobalSettingsAccountMailboxInfo from 'owa-account-source-list-store/lib/selectors/getGlobalSettingsAccountMailboxInfo';
import { registerLPCInitializeOrchestrator } from 'owa-lpc-initialize';
import loadPlacesSettings from 'places-settings/lib/loadPlacesSettings';
import {
    getModuleState,
    initializeWeekPickerViewState,
    setModuleStateSafe,
} from 'places-user-session-stores';
import { handleMetaOSDeepLinkRedirection } from './utils/handleMetaOSDeepLinkRedirection';

import type { SessionData } from 'owa-service/lib/types/SessionData';
export async function initializeState(sessionData?: SessionData | undefined) {
    placesFwkBootStartAction(sessionData?.isOffline ? 'OfflineSessionData' : 'OnlineSessionData');
    loadPlacesSettings(
        getGlobalSettingsAccountMailboxInfo(),
        true /** shouldUseMainThread to send via main thread to avoid worker thread set up cost */
    );

    setModuleStateSafe('boot');
    setTimeout(() => {
        // TODO: remove this when we improve appView FSM for error handling and other modules etc.
        // forces us to remove loading screen if we don't complete in time to maintain similar behavior as of developing.
        if (getModuleState() !== 'complete') {
            setModuleStateSafe('complete');
        }
    }, 5000 /** 5s */);

    registerLPCInitializeOrchestrator();

    // We would have timezone initialized by now
    // so initializing date here would ensure application layer
    // to use user's timezone instead of UTC
    initializeWeekPickerViewState();
    initializePlacesFinderStore();

    // Handles MetaOS deep link redirection
    await handleMetaOSDeepLinkRedirection();
    return null;
}
