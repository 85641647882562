import type { CalendarGroup } from 'owa-graph-schema';
import getCalendarGroupKey from '../utils/getCalendarGroupKey';
import getStore from '../store/store';
import { mutator, mutatorAction } from 'satcheljs';
import { updateCalendarGroup } from '../actions/publicActions';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
mutator(updateCalendarGroup, actionMessage => {
    const { groupId, userIdentity, calendarGroup } = actionMessage;

    const store = getStore();
    const groupKey = getCalendarGroupKey(userIdentity, groupId);

    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (16,11): Type 'CalendarGroup | undefined' is not assignable to type 'CalendarGroup'.
    // @ts-expect-error
    const calendarGroupInStore: CalendarGroup = store.calendarGroupsMapping.get(groupKey);
    const { calendarGroupId, ...calendarGroupWithoutItemId } = calendarGroup;

    if (calendarGroupInStore) {
        Object.keys(calendarGroupWithoutItemId).forEach(property => {
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS7053 (24,13): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'CalendarGroup'.
            // @ts-expect-error
            calendarGroupInStore[property] = calendarGroupWithoutItemId[property];
        });
    }
});

export const setCalendarGroupMappingForKey = mutatorAction(
    'setCalendarGroupMappingForKey',
    (groupKey: string, calendarGroup: CalendarGroup) => {
        getStore().calendarGroupsMapping.set(groupKey, calendarGroup);
    }
);
