import type RequestState from 'eventify-schema/lib/RequestState';
import { ObservableMap } from 'mobx';
import { createAccessors } from 'owa-viewstate-store';
import { createStore } from 'satcheljs';
import createComposeWorkGroupModalViewState from '../utils/composeWorkGroupModalUtils';
import createEmptyThemeImageSearchViewState from '../utils/createEmptyThemeImageSearchViewState';
import createGuidanceModalViewState from '../utils/guidanceModalUtils';
import initializeWorkGroupsComposeErrors from '../utils/initializeWorkGroupsComposeErrors';

import type { WorkGroupMinimized } from './schema/MinimizedWorkGroup';

import type EventifyEvent from 'eventify-schema/lib/EventifyEvent';
import type EventsGroup from 'eventify-schema/lib/EventsGroup';
import type EventWithMaster from './schema/EventWithMaster';

// This store encase both the old implementation of the store for places-work-groups and the new implementation that will be used in
// hybridspace-home (people tab). The object Store is getting exported exclusively for the first implementation and SHOULD NOT BE USED
// by any other implementation. This package will also need to pass through a classes cleaning process to minize the amount of code that
// it needs to work. WI: https://outlookweb.visualstudio.com/MicrosoftPlaces/_workitems/edit/287856
const getStore = createStore('places-work-groups-store', {
    currentEvent: null as EventifyEvent | null,
    currentEventsGroup: null as EventsGroup | null,
    originalEventsGroup: null as EventsGroup | null,
    guidanceInstancesWithMasters: null as EventWithMaster[] | null,
    loadGuidancesFailed: false,
    loadGuidancesInstancesRequestState: 0,
    composeFormIsDeleteRequestInProgress: 0,
    composeFormIsSaveRequestInProgress: 0,
    loadAllWorkGroupsRequestState: 0,
    themeImageSearchViewState: createEmptyThemeImageSearchViewState(),
    guidanceModalViewState: createGuidanceModalViewState(),
    composeWorkGroupModalViewState: createComposeWorkGroupModalViewState(),
    currentChildEventFromWorkGroup: null as EventifyEvent | null,
    selectedPivot: 'swimlanes' as 'swimlanes' | 'facepiles',
    workGroups: new ObservableMap<string, WorkGroupMinimized>(),
    workGroupsComposeErrors: initializeWorkGroupsComposeErrors(),
    isSideBarOpen: false,
});

// Because of the reasons explained in line 16-19, this shouldn't be used by any new implementation. Please don't use this object and create
// a getter and setter for any new values added to the store.
export const store = getStore();

export const {
    getComposeFormIsDeleteRequestInProgress,
    getComposeFormIsSaveRequestInProgress,
    getCurrentEvent,
    getCurrentEventsGroup,
    getCurrentChildEventFromWorkGroup,
    getGuidanceInstancesWithMasters,
    getLoadGuidancesInstancesRequestState,
    getLoadGuidancesFailed,
    getLoadAllWorkGroupsRequestState,
    getOriginalEventsGroup,
    getThemeImageSearchViewState,
    getGuidanceModalViewState,
    getComposeWorkGroupModalViewState,
    getSelectedPivot,
    getWorkGroups,
    getWorkGroupsComposeErrors,
    getIsSideBarOpen,
    setCurrentEvent,
    setCurrentEventsGroup,
    setCurrentChildEventFromWorkGroup,
    setLoadGuidancesInstancesRequestState,
    setLoadGuidancesFailed,
    setLoadAllWorkGroupsRequestState,
    setOriginalEventsGroup,
    setThemeImageSearchViewState,
    setGuidanceModalViewState,
    setComposeWorkGroupModalViewState,
    setSelectedPivot,
    setWorkGroups,
    setWorkGroupsComposeErrors,
    setIsSideBarOpen,
} = createAccessors('places-work-groups-store', getStore);
