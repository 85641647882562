import { AccTabList } from 'accelerator-common/lib/AccTabList';
import { PlacesFluentProvider } from 'hybridspace-common/lib/PlacesFluentProvider';
import { BottomSheet } from 'mini-bottom-sheet-animated';
import { addDocumentEventListener } from 'owa-event-listener';
import LineHorizontal3 from 'owa-fluent-icons-svg/lib/icons/LineHorizontal3Regular';
import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import React from 'react';
import { FocusTrapZone, Icon } from '@fluentui/react';
import { Button, Caption1Strong, Divider, mergeClasses, Tooltip } from '@fluentui/react-components';
import { useId } from '@fluentui/react-hooks';
import MoreMenuListItems from '../PlacesMoreDropdown/MoreMenuListItems';
import { navigationMenuButtonLabel, settingsDividerText } from './SmallScreenNav.locstring.json';
import {
    bottomSheet,
    bottomSheetContainer,
    buttonActive,
    buttonInactive,
    buttonMargin,
    divider,
    divTitle,
} from './SmallScreenNav.scss';

import type { OnVisibleChangeData } from '@fluentui/react-components';

import type { AccTabItem } from 'accelerator-common/lib/AccTabList';
const HamburgerIcon = <Icon iconName={LineHorizontal3} />;

export default observer(function SmallScreenNav({
    links,
    navigate,
    selectedRoute,
    isSmallScreen,
    isStandaloneApp,
}: {
    links: AccTabItem[];
    navigate: (newSelectedKey: string) => void;
    selectedRoute: string;
    isSmallScreen: boolean;
    isStandaloneApp: boolean;
}) {
    const bottomSheetId = useId('nav-bottom-sheet');
    const focusTrapZone = React.useRef<HTMLDivElement | null>(null);

    const [isBottomSheetOpen, setIsBottomSheetOpen] = React.useState<boolean>(false);
    const [active, setActive] = React.useState<boolean>(false);
    const [visible, setVisible] = React.useState(false);

    // Create a listener if the user hits the escape key to close the popover/bottom sheet
    React.useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setIsBottomSheetOpen(false);
                setActive(false);
                setVisible(false);
            }
        };

        addDocumentEventListener('SmallScreenNav', 'keydown', handleEscKey);
    }, []);

    const handleSelectedKeyChange = React.useCallback(
        (newSelectedKey: string) => {
            navigate(newSelectedKey);
            setIsBottomSheetOpen(false);
            setActive(false);
        },
        [navigate]
    );

    const handleDismiss = React.useCallback(() => {
        setIsBottomSheetOpen(false);
        setActive(false);
        setVisible(false);
    }, []);

    const onSmallScreenButtonClick = React.useCallback(() => {
        setIsBottomSheetOpen(true);
        setActive(true);
        focusTrapZone.current?.focus();
    }, []);

    React.useMemo(() => {
        if (isSmallScreen) {
            setIsBottomSheetOpen(false);
            setActive(false);
        }
    }, [isSmallScreen]);

    const handleVisibleChange = React.useCallback(
        (_ev: any, data: OnVisibleChangeData) => {
            if (isBottomSheetOpen) {
                setVisible(false);
            } else {
                setVisible(data.visible);
            }
        },
        [isBottomSheetOpen]
    );

    return (
        <>
            {isSmallScreen && (
                <>
                    <Tooltip
                        content={loc(navigationMenuButtonLabel)}
                        relationship="label"
                        visible={visible && !isBottomSheetOpen}
                        onVisibleChange={handleVisibleChange}
                    >
                        <Button
                            appearance="transparent"
                            icon={HamburgerIcon}
                            onClick={onSmallScreenButtonClick}
                            className={
                                isStandaloneApp
                                    ? mergeClasses(
                                          active ? buttonActive : buttonInactive,
                                          buttonMargin
                                      )
                                    : active
                                    ? buttonActive
                                    : buttonInactive
                            }
                            aria-label={loc(navigationMenuButtonLabel)}
                            data-telemetry-id="SmallScreenNavButton"
                        />
                    </Tooltip>
                    <BottomSheet
                        id={bottomSheetId}
                        open={isBottomSheetOpen}
                        onOverlayClick={handleDismiss}
                        noSafeArea={true}
                        containerStyles={bottomSheetContainer}
                    >
                        <PlacesFluentProvider>
                            <FocusTrapZone
                                ref={focusTrapZone}
                                forceFocusInsideTrap={false}
                                isClickableOutsideFocusTrap={true}
                            >
                                <div className={bottomSheet}>
                                    <AccTabList
                                        items={links}
                                        selectedKey={selectedRoute}
                                        onSelectedKeyChange={handleSelectedKeyChange}
                                        isVertical={true}
                                        appearance={'subtle'}
                                    />
                                    <Divider className={divider}></Divider>
                                    <div className={divTitle}>
                                        <Caption1Strong>{loc(settingsDividerText)}</Caption1Strong>
                                    </div>
                                    <MoreMenuListItems />
                                </div>
                            </FocusTrapZone>
                        </PlacesFluentProvider>
                    </BottomSheet>
                </>
            )}
        </>
    );
},
'SmallScreenNav');
