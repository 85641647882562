import { observer } from 'owa-mobx-react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccTabList } from './AccTabList';

import type { NavigateFunction } from 'react-router-dom';
import type { AccTabItem } from './AccTabList';

/**
 * An Accelerator TabList that is specialized to do navigation
 * using react-router-dom. The key of each item points to the
 * URL that the application navigates to when the tab is clicked.
 */
export const AccNavTabList = observer(function AccNavTabList({
    items,
    onBeforeNavigate,
    ...otherProps
}: Omit<React.ComponentProps<typeof AccTabList>, 'selectedKey' | 'onSelectedKeyChange'> & {
    navigate?: NavigateFunction;
    onBeforeNavigate?: () => void | boolean;
}) {
    const navigate = useNavigate();
    const currentRoute = useCurrentRoute(items);

    // Prevent the page from being re-rendered when the current tab value is re-selected
    const onNavigate = React.useCallback(
        (key: string) => {
            const stopNavigation = onBeforeNavigate?.();

            if (!stopNavigation && currentRoute !== key) {
                navigate(key);
            }
        },
        [currentRoute, navigate, onBeforeNavigate]
    );

    return (
        <AccTabList
            {...otherProps}
            items={items}
            selectedKey={currentRoute}
            onSelectedKeyChange={onNavigate}
        />
    );
}, 'AccNavTabList');

/**
 * Given a set of tab items with keys that represent navigation paths,
 * returns the key of the current route.
 */
export function useCurrentRoute(items: AccTabItem[]) {
    // I've tried using matchRoutes for this, but it fails to detect when we have extra parameters
    // To avoid that, I need to call matchPath myself, and at that point, prioritize and loop on the links myself.
    // If I'm going to do that, we might as well skip the whole creation of objects and reg-exps and just call indexOf
    // directly and save us the trouble of spending a bunch of cycles trying to figure out the active link.
    const pathname = useLocation().pathname.toLowerCase();
    for (let i = items.length - 1; i >= 0; i--) {
        if (pathname.indexOf(items[i].key.toLowerCase()) != -1) {
            return items[i].key;
        }
    }
    return '';
}
