import { lazyHybridspaceWorkWeekCalendarSurfaceModule } from 'hybridspace-calendar';
import { lazyWorkplanModule } from 'hybridspace-home';
import { getWorkplanFWH, lazyHybridspaceHomeAppStore } from 'hybridspace-home-app-store';
import { createDateRange } from 'owa-datetime-utils';
import { loadFWHAsync, loadPlaces } from 'places-explore-operations';
import { placesFwkRootComponentMountedAction } from 'places-fwk-actions';
import { lazyPlacesPeopleStore } from 'places-people-store';
import { getCurrentDate, getCurrentDateRange } from 'places-user-session-stores';
import { lazyPlacesWorkGroupsPeopleMergedModule } from 'places-work-groups-people-merged';
import { lazyPlacesWorkGroupsStoreModule, viewWorkPlan } from 'places-work-groups-store';

import type { LoaderFunction } from 'react-router-dom';

// Loader for the work-plan work groups route
// (/people/workgroup/:id)
export const WorkplanWorkGroupsLoader: LoaderFunction = async (...args) => {
    // need to import the store to ensure it is initialized
    await lazyHybridspaceHomeAppStore.import();

    // starting agenda panel marker here as it is the first function before react render starts.
    placesFwkRootComponentMountedAction('PlacesAgendaPanelRoot');

    // Always run this to handle swapping between groups
    viewWorkPlan(...args);

    const fwh = getWorkplanFWH();
    // Skip critical data
    if (fwh != undefined) {
        return null;
    }

    //non critical data
    loadPlaces();
    await lazyPlacesPeopleStore.import();

    // Define critical data: workplan module, and FWH for the week
    const fetchFWH = loadFWHAsync(getCurrentDateRange());
    const fetchTodayFWH = loadFWHAsync(createDateRange(getCurrentDate(), 1));
    const workplanModule = lazyWorkplanModule.import();
    const calendarModule = lazyHybridspaceWorkWeekCalendarSurfaceModule.import();
    const mergedModule = lazyPlacesWorkGroupsPeopleMergedModule.import();
    const workgroupsStoreModule = lazyPlacesWorkGroupsStoreModule.import();

    const criticalData: Promise<any>[] = [
        workplanModule,
        fetchFWH,
        fetchTodayFWH,
        calendarModule,
        mergedModule,
        workgroupsStoreModule,
    ];

    return Promise.all(criticalData);
};
