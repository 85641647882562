import { createLazyComponent, LazyAction, LazyModule } from 'owa-bundling';

import type { LoaderFunction } from 'react-router-dom';

export { default as useWorkplaceEventsLinks } from './useWorkplaceEventsLinks';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PlacesWorkplaceEvents" */ './lazyIndex')
);

export const WorkplaceEventsPageWrapper = createLazyComponent(
    lazyModule,
    m => m.WorkplaceEventsPageWrapper
);

export const YourEventsPageWrapper = createLazyComponent(lazyModule, m => m.YourEventsPageWrapper);

export const ComposeForm = createLazyComponent(lazyModule, m => m.ComposeForm);
export const EventifyDashboard = createLazyComponent(lazyModule, m => m.EventifyDashboard);
export const EventsGroupComposeForm = createLazyComponent(
    lazyModule,
    m => m.EventsGroupComposeForm
);
export const EventsGroupReadModule = createLazyComponent(lazyModule, m => m.EventsGroupReadModule);
export const ReadModule = createLazyComponent(lazyModule, m => m.ReadModule);

// Create some lazy imports for route loaders to isolate places-boot from eventify-client
const lazyNewEvent = new LazyAction(lazyModule, m => m.newEvent);
const lazyViewEvent = new LazyAction(lazyModule, m => m.viewEvent);
const lazyEditEvent = new LazyAction(lazyModule, m => m.editEvent);
const lazyNewEventGroup = new LazyAction(lazyModule, m => m.newEventGroup);
const lazyViewEventGroup = new LazyAction(lazyModule, m => m.viewEventGroup);
const lazyEditEventGroup = new LazyAction(lazyModule, m => m.editEventGroup);
const lazyOrganizingEvents = new LazyAction(lazyModule, m => m.organizingEvents);
const lazyAttendingEvents = new LazyAction(lazyModule, m => m.attendingEvents);
const lazyAllEvents = new LazyAction(lazyModule, m => m.allEvents);

// Create some basic wrappers to simplify the usage in the places routing.
export const newEvent: LoaderFunction = async (...args) => lazyNewEvent.importAndExecute(...args);
export const viewEvent: LoaderFunction = async (...args) => lazyViewEvent.importAndExecute(...args);
export const editEvent: LoaderFunction = async (...args) => lazyEditEvent.importAndExecute(...args);
export const newEventGroup: LoaderFunction = async (...args) =>
    lazyNewEventGroup.importAndExecute(...args);
export const viewEventGroup: LoaderFunction = async (...args) =>
    lazyViewEventGroup.importAndExecute(...args);
export const editEventGroup: LoaderFunction = async (...args) =>
    lazyEditEventGroup.importAndExecute(...args);
export const organizingEvents: LoaderFunction = async (...args) =>
    lazyOrganizingEvents.importAndExecute(...args);
export const attendingEvents: LoaderFunction = async (...args) =>
    lazyAttendingEvents.importAndExecute(...args);
export const allEvents: LoaderFunction = async (...args) => lazyAllEvents.importAndExecute(...args);
