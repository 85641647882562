import { owaDate } from 'owa-datetime';

import type GuidanceModalViewState from '../store/schema/GuidanceModalViewState';

export default function createGuidanceModalViewState(): GuidanceModalViewState {
    return {
        isExpanded: false,
        isEditMode: false,
        saveCreateGuidanceButtonOnOpenDisabled: false,
        saveCreateGuidanceButtonOnValidationDisabled: false,
        isGuidanceFullViewExpanded: false,
        guidanceSelectedDay: 0,
        changesMade: false,
        guidanceSelectedDate: owaDate(),
        isEndDateEdited: false,
    };
}
