import type { AccTabItem } from 'accelerator-common/lib/AccTabList';
import { useRoleContext, UserRoles } from 'hybridspace-common/lib/context';
import FormFilled from 'owa-fluent-icons-svg/lib/icons/FormFilled';
import FormRegular from 'owa-fluent-icons-svg/lib/icons/FormRegular';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { isPlacesWorkplaceEventsEnabled } from 'places-settings';
import React from 'react';
import { workplaceEvents } from './useWorkplaceEventsLinks.locstring.json';

export default function useWorkplaceEventsLinks(): AccTabItem[] {
    const mailboxInfo = getModuleContextMailboxInfo();
    const isWorkplaceEventsEnabled = isPlacesWorkplaceEventsEnabled(mailboxInfo);
    const roleSwitcherContext = useRoleContext();
    const shouldHideEventsLinks =
        roleSwitcherContext.userRole == UserRoles.Admin || !isWorkplaceEventsEnabled;

    return React.useMemo(
        () => [
            {
                id: 'Events',
                key: '/events/',
                icon: FormRegular,
                activeIcon: FormFilled,
                textResourceId: workplaceEvents,
                ariaLabelResourceId: workplaceEvents,
                hidden: shouldHideEventsLinks,
            },
        ],
        [shouldHideEventsLinks]
    );
}
