import type { LazyModuleType } from 'owa-bundling';
import { createLazyComponent, LazyAction, LazyImport, LazyModule } from 'owa-bundling';

import type { GuidanceCardProps } from './components/dashboard/GuidanceCard';
import type { WorkGroupsPercentInOfficeProps } from './components/dashboard/WorkGroupsPercentInOffice';
import type { WorkPlansFacePilesProps } from './components/dashboard/WorkPlansFacePiles';
import type { WorkPlansSwimLanesProps } from './components/dashboard/WorkPlansSwimLanes';

import type { UpdateGuidanceWrapperProps } from './components/dashboard/UpdateGuidanceWrapper';

import type { LoaderFunction, ShouldRevalidateFunction } from 'react-router-dom';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PlacesWorkGroups" */ './lazyIndex')
);

// Typescript linter doesn't like non-typed "m", so need to shape the input to avoid linter complaints.
export const WorkGroupsDashboard = createLazyComponent(
    lazyModule,
    (m: { WorkGroupsDashboard: any }) => m.WorkGroupsDashboard
);
export const NoWorkGroupsFound = createLazyComponent(
    lazyModule,
    (m: { NoWorkGroupsFound: any }) => m.NoWorkGroupsFound
);
export const WorkGroupsModuleHeader = createLazyComponent(
    lazyModule,
    (m: { WorkGroupsModuleHeader: any }) => m.WorkGroupsModuleHeader
);

export const UpdateGuidanceWrapper = createLazyComponent<
    UpdateGuidanceWrapperProps,
    LazyModuleType<typeof lazyModule>,
    {}
>(lazyModule, m => m.UpdateGuidanceWrapper);

export const GuidanceCard = createLazyComponent<
    GuidanceCardProps,
    LazyModuleType<typeof lazyModule>,
    {}
>(lazyModule, m => m.GuidanceCard);

export const WorkGroupsPercentInOffice = createLazyComponent<
    WorkGroupsPercentInOfficeProps,
    LazyModuleType<typeof lazyModule>,
    {}
>(lazyModule, m => m.WorkGroupsPercentInOffice);

export const WorkPlansFacePiles = createLazyComponent<
    WorkPlansFacePilesProps,
    LazyModuleType<typeof lazyModule>,
    {}
>(lazyModule, m => m.WorkPlansFacePiles);

export const WorkPlansSwimLanes = createLazyComponent<
    WorkPlansSwimLanesProps,
    LazyModuleType<typeof lazyModule>,
    {}
>(lazyModule, m => m.WorkPlansSwimLanes);

export const WorkGroupScheduleHeader = createLazyComponent<
    {},
    LazyModuleType<typeof lazyModule>,
    {}
>(lazyModule, m => m.WorkGroupScheduleHeader);

export const WORKGROUPS_ROOT = 'workgroups';

const lazyAllWorkGroups = new LazyAction(lazyModule, m => m.allWorkGroupsLoader);
// LazyImport because we don't need importAndExecute
const lazyShouldAllWorkGroupsRevalidate = new LazyImport(
    lazyModule,
    m => m.shouldAllWorkGroupsRevalidate
);

export const allWorkGroups: LoaderFunction = async (...args) =>
    lazyAllWorkGroups.importAndExecute(...args);
export const allWorkGroupsRevalidate: ShouldRevalidateFunction = ({
    currentUrl,
    nextUrl,
    ...restOfArgs
}) => {
    // Revalidate if we think it should revalidate based off of the status.
    // We are using `tryImportForRender` since we need the function to be synchronous,
    // and we can fallback onto checking if the pathname is the same as the "first"
    // time we run this function
    return (
        lazyShouldAllWorkGroupsRevalidate.tryImportForRender()?.({
            currentUrl,
            nextUrl,
            ...restOfArgs,
        }) ?? currentUrl.pathname == nextUrl.pathname
    );
};

export const lazyGetLocationType = new LazyAction(lazyModule, m => m.getLocationType);
