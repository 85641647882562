import type { Room } from 'owa-room-selection-types';
import { getSelectedLocationType, getSuggestions } from './store';
import { type PlacesFinderLocationTypeFilter } from './schema/PlacesFinderViewState';

export function getSuggestionsWithLocationType(viewStateId: string): Room[] {
    const allSuggestions = getSuggestions(viewStateId);
    const selectedLocationType = getSelectedLocationType(viewStateId);

    if (!allSuggestions || selectedLocationType == 'All') {
        return allSuggestions;
    }

    return allSuggestions.filter(suggestion => suggestion.locationType === selectedLocationType);
}

// This function is used by the PlacesFinderResultsTabList component to calculate the DesksCount and WorkSpacesCount.
export function getUnfilteredSuggestions(viewStateId: string): Room[] {
    return getSuggestions(viewStateId);
}
