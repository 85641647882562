import { lazyHybridspaceWorkWeekCalendarSurfaceModule } from 'hybridspace-calendar';
import { lazyCollaboratorsModule } from 'hybridspace-collaborators';
import { lazyHybridspaceEditCollabModule } from 'hybridspace-common/lib/components/EditCollaboratorsButton';
import { lazyWorkplanModule } from 'hybridspace-home';
import { getWorkplanFWH, lazyHybridspaceHomeAppStore } from 'hybridspace-home-app-store';
import { createDateRange } from 'owa-datetime-utils';
import { loadCollaboratorsLocations, loadFWHAsync, loadPlaces } from 'places-explore-operations';
import { placesFwkRootComponentMountedAction, placesLoaderAction } from 'places-fwk-actions';
import { lazyPlacesPeopleStore } from 'places-people-store';
import { getCurrentDate, getCurrentDateRange } from 'places-user-session-stores';

import type { LoaderFunction } from 'react-router-dom';
// Loader for the work-plan route
// (/people) if user is Premium
// (/) if user is Core
export const WorkplanLoader: LoaderFunction = async () => {
    placesLoaderAction('PlacesWorkplanLoader');

    // need to import the store to ensure it is initialized
    await lazyHybridspaceHomeAppStore.import();

    // starting agenda panel marker here as it is the first function before react render starts.
    placesFwkRootComponentMountedAction('PlacesAgendaPanelRoot');

    const fwh = getWorkplanFWH();
    // Skip critical data
    if (fwh != undefined) {
        return null;
    }

    const currentDateRange = getCurrentDateRange();

    //non critical data
    loadPlaces();
    await lazyPlacesPeopleStore.import();
    loadCollaboratorsLocations(currentDateRange);

    // Define critical data: workplan module, and FWH for the week
    const fetchFWH = loadFWHAsync(getCurrentDateRange());
    const fetchTodayFWH = loadFWHAsync(createDateRange(getCurrentDate(), 1));
    const workplanModule = lazyWorkplanModule.import();
    const calendarModule = lazyHybridspaceWorkWeekCalendarSurfaceModule.import();
    const collabTitleModule = lazyHybridspaceEditCollabModule.import();
    const collaboratorsModule = lazyCollaboratorsModule.import();

    const criticalData: Promise<any>[] = [
        workplanModule,
        fetchFWH,
        fetchTodayFWH,
        calendarModule,
        collabTitleModule,
        collaboratorsModule,
    ];

    return Promise.all(criticalData);
};
