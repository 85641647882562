import { logGreyError } from 'owa-analytics';
import { isRunningInMetaOSHub } from 'owa-config';
import { scrubForPii } from 'owa-config/lib/scrubForPii';

export function getNewMetaOSDeeplinkUrl(deeplinkPathnameSearchParam: string) {
    if (!deeplinkPathnameSearchParam.startsWith('/')) {
        const error = new Error('invalid deeplink');
        logGreyError('DeepLinkUrlInvalid', error, {
            contentUrl: scrubForPii(deeplinkPathnameSearchParam),
        });
        throw error;
    }
    // Parse the current and deeplink url
    const currentUrl = new URL(window.location.href);
    const newUrl = new URL(deeplinkPathnameSearchParam, window.location.origin + '/places');

    // Get the current and deep link search parameters
    const searchParams = new URLSearchParams(currentUrl.search);
    const newSearchparams = new URLSearchParams(newUrl.search);

    // Merge with the additional parameters, ensuring no duplication
    // This will replace any existing parameter with the same key
    for (const [key, value] of newSearchparams.entries()) {
        searchParams.set(key, value);
    }

    return (
        (isRunningInMetaOSHub() ? '/hosted' : '') +
        '/places' +
        newUrl.pathname +
        `?${searchParams.toString()}`
    );
}
