import { observer } from 'owa-mobx-react';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { EventsScreen } from './EventsScreen';

const EventsScreenElement = <EventsScreen />;

export default observer(function HybridspaceEventsRouter() {
    return (
        <Routes>
            <Route path="*" element={EventsScreenElement} />
        </Routes>
    );
}, 'HybridspaceEventsRouter');
