export enum HomeRouteId {
    ROOT = 'root',
    ROOT_SPLAT = 'root-splat',
    HOME = 'home',
    HOME_REDIRECT = 'home-redirect',
    BUILDINGS_DEEP_LINK = 'building-deep-link',
    WORK_PLAN = 'workplan',
    PEOPLE = 'people',
    WORKPLAN_HOME_REDIRECT = 'workplan-home-redirect',
    PEOPLE_HOME_REDIRECT = 'people-home-redirect',
    WORK_GROUPS = 'workgroups',
    EVENTS = 'events',
    EVENTS_ALL_EXPANDED = 'all-expandedCollection',
    EVENTS_ORGANIZING_EXPANDED = 'organizing-expandedCollection',
    EVENTS_ATTENDING_EXPANDED = 'attending-expandedCollection',
    EVENTS_NEW = 'new',
    EVENTS_EVENTID = 'eventid',
    EVENTS_EDIT = 'edit',
    EVENTS_NEW_GROUP = 'group',
    EVENTS_SELECTED_GROUP = 'selected-group',
    EVENTS_EDIT_GROUP = 'edit-group',
    ADMIN = 'admin',
    ADMIN_SPACE_MANAGEMENT = 'space-management',
    ADMIN_SPACE_ANALYTICS = 'space-analytics',
    DEV_PANEL = 'devpanel',
}
