import { createLazyComponent, LazyModule } from 'owa-bundling';
import { Loading, NetworkError } from 'owa-interstitial';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "HybridspaceEventsScreen" */ './lazyIndex')
);

export const EventsScreen = createLazyComponent(
    lazyModule,
    m => m.EventsScreen,
    Loading,
    NetworkError
);
