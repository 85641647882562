import fetchPlacesSettings from './fetchPlacesSettings';
import {
    hasLoaded,
    resetPlacesSettings,
    setPlacesSettings,
    setPlacesSettingsFailed,
} from './store';

import type { MailboxInfo } from 'owa-client-types';

export default function loadPlacesSettings(
    mailboxInfo: MailboxInfo,
    shouldUseMainThread?: boolean
) {
    if (!hasLoaded(mailboxInfo)) {
        resetPlacesSettings(mailboxInfo);
        fetchPlacesSettings(mailboxInfo, shouldUseMainThread)
            .then(results => setPlacesSettings(mailboxInfo, results))
            .catch(reason => setPlacesSettingsFailed(mailboxInfo, reason));
    }
}
