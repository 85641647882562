import { getCdnUrl } from 'owa-config';
import ArrowClockwiseRegular from 'owa-fluent-icons-svg/lib/icons/ArrowClockwiseRegular';
import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import React, { useCallback } from 'react';
import { Icon } from '@fluentui/react';
import { Body2, Button, Spinner, Subtitle1, Tooltip } from '@fluentui/react-components';
import { ErrorCircleRegular } from '@fluentui/react-icons';
import {
    hybridSpaceErrorNotFoundMessage,
    hybridSpaceErrorRetryActionButtonLabel,
    hybridSpaceErrorRetryImageAriaLabel,
    hybridSpaceErrorRetryTitle,
} from './HybridspaceError.locstring.json';
import {
    bodyColor,
    container,
    miniatureContainer,
    spinner,
    textButtonContainer,
    textContainer,
} from './HybridspaceError.scss';

import type { ResourceId } from 'owa-localize';
import type { PositioningShorthand } from '@fluentui/react-components';

const retryIcon = <Icon iconName={ArrowClockwiseRegular} />;
const errorIcon = <ErrorCircleRegular />;
const retryImage = `https:${getCdnUrl()}assets/places/errors/AlertExclamationMark.png`;
const spinnerElement = <Spinner className={spinner} size="tiny" />;

/**
 * wiki: https://dev.azure.com/outlookweb/MicrosoftPlaces/_wiki/wikis/MicrosoftPlaces.wiki/13460/How-To-Add-an-error-component
 */
export default observer(function HybridspaceError({
    retry,
    buttonLabel,
    errorMessage,
    brandedButtonStyle,
    miniature,
    miniatureTooltipPosition,
    loading,
    errorTitle,
}: {
    retry?: () => void; // If not provided, the page will be hard reloaded, which is not recommended for most cases
    buttonLabel?: ResourceId;
    errorMessage?: ResourceId;
    brandedButtonStyle?: boolean;
    miniature?: boolean;
    miniatureTooltipPosition?: PositioningShorthand;
    loading?: boolean;
    errorTitle?: ResourceId;
}) {
    const message = errorMessage ?? hybridSpaceErrorNotFoundMessage;
    const title = errorTitle ?? hybridSpaceErrorRetryTitle;

    const buttonTitle = buttonLabel ?? hybridSpaceErrorRetryActionButtonLabel;
    const isLoading = !!loading;
    const retryFunction = useCallback(() => {
        if (retry) {
            retry();
        } else {
            window.location.reload();
        }
    }, [retry]);

    if (miniature) {
        return (
            <div className={miniatureContainer} data-telemetry-id="PlacesErrorBoundary">
                <Tooltip
                    content={loc(buttonTitle)}
                    relationship="label"
                    positioning={miniatureTooltipPosition}
                >
                    <Button
                        data-telemetry-id={'PlacesErrorBoundaryButton'}
                        onClick={retryFunction}
                        disabled={isLoading}
                        shape="circular"
                        icon={errorIcon}
                        appearance={brandedButtonStyle ? 'primary' : undefined}
                    />
                </Tooltip>
            </div>
        );
    }

    return (
        <div className={container} data-telemetry-id="PlacesErrorBoundary">
            <img src={retryImage} alt={loc(hybridSpaceErrorRetryImageAriaLabel)} />
            <div className={textButtonContainer}>
                <div className={textContainer}>
                    <Subtitle1>{loc(title)}</Subtitle1>
                    <Body2 className={bodyColor}>{loc(message)}</Body2>
                </div>

                <Button
                    data-telemetry-id={'PlacesErrorBoundaryButton'}
                    disabled={isLoading}
                    size="large"
                    icon={retryIcon}
                    onClick={retryFunction}
                    appearance={brandedButtonStyle ? 'primary' : undefined}
                >
                    {loc(buttonTitle)}
                    {isLoading && spinnerElement}
                </Button>
            </div>
        </div>
    );
}, 'HybridspaceError');
