import { isFeatureEnabled } from 'owa-feature-flags';
import { isApolloError } from '@apollo/client';

import type queryPlacesSettings from './queryPlacesSettings';

/**
 * Given the tenant settings query results, looking for IsPlacesCoreEnabled or IsPlacesPremiumEnabled,
 * determine if we need to fallback to querying only for PlacesEnabled.
 * This can happen is a newer client talk to an older server and the server
 * does not yet recognize the new flag. If it happens, we revert to the old
 * behavior and use PlacesEnabled as flag to enable the web app.
 * NOTE: This code can be removed once all servers are fully upgraded and responding to IsPlacesCoreEnabled and IsPlacesPremiumEnabled.
 * NOTE: places-settings-disable-fallback stops us from doing this fallback behavior.
 *       we can turn it on if we have a reason to believe we're doing the wrong thing with this fallback.
 */
export default function shouldFallbackToPlacesEnabledOnly(
    results: Awaited<ReturnType<typeof queryPlacesSettings>>
) {
    const e = results.error;
    return (
        !results.data &&
        isApolloError(e) &&
        e.graphQLErrors[0]?.path?.[0] == 'batchGetEffectiveSettings' &&
        e.graphQLErrors[0]?.extensions?.DependencyFailures?.SettingService == 'InvalidArgument' &&
        (e.graphQLErrors[0]?.extensions.InnerMessage.indexOf('IsPlacesCoreEnabled') != -1 ||
            e.graphQLErrors[0]?.extensions.InnerMessage.indexOf('IsPlacesPremiumEnabled') != -1) &&
        !isFeatureEnabled('places-settings-disable-fallback')
    );
}
