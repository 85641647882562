import { getUserExternalDirectoryId } from 'accelerator-aad/lib/getUserExternalDirectoryId';
import { ObservableMap } from 'mobx';
import { getItem, setItem } from 'owa-local-storage';
import { createAccessors } from 'owa-viewstate-store';
import { createStore, mutatorAction } from 'satcheljs';

import type { Place } from 'owa-graph-schema';

/**
 * This function returns the key for the tenantHasBuildings property in local storage
 */
export function getTenantHasBuildingsKey() {
    const ownerId = getUserExternalDirectoryId();
    return `${ownerId}-TENANT_HAS_BUILDINGS`;
}

const getStore = createStore('places-places-store', {
    places: new ObservableMap<string, Place>(), // Map of all the buildings as the Place object, key is building id
    tenantHasBuildings: undefined as boolean | undefined,
});

const store = getStore();

const { getPlaces, getTenantHasBuildings, setTenantHasBuildings } = createAccessors(
    'places-places-store',
    getStore
);

export const setPlace = mutatorAction('setPlace', (buildingId: string, place: Place) => {
    store.places.set(buildingId, place);
});

export function setTenantHasBuildingsCacheValue() {
    const tenantHasBuildingsCacheValue = getItem(window, getTenantHasBuildingsKey());
    if (tenantHasBuildingsCacheValue) {
        try {
            const tenantHasBuildings = JSON.parse(tenantHasBuildingsCacheValue);
            if (typeof tenantHasBuildings === 'boolean') {
                setTenantHasBuildings(tenantHasBuildings);
            } else {
                throw new Error('Invalid value');
            }
        } catch (e) {
            //remove invalid value from cache
            localStorage.removeItem(getTenantHasBuildingsKey());
        }
    }
}

export function setTenantHasBuildingsStoreAndCache(value: boolean) {
    setTenantHasBuildings(value);
    setItem(window, getTenantHasBuildingsKey(), JSON.stringify(value));
}

export { getPlaces, getTenantHasBuildings };
