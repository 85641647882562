import { createLazyComponent, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "HybridspaceHomeDashboardBeta1" */ './lazyIndex')
);

export const HybridspaceHomeDashboard = createLazyComponent(
    lazyModule,
    m => m.HybridspaceHomeDashboard
);

export const HomeDashboard = createLazyComponent(lazyModule, m => m.HomeDashboard);

export const HybridspaceHomeDashboardContent = createLazyComponent(
    lazyModule,
    m => m.HybridspaceHomeDashboardContent
);

export const HybridspaceWeeklyWorkRoutineSection = createLazyComponent(
    lazyModule,
    m => m.HybridspaceWeeklyWorkRoutineSection
);
