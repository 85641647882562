import type { CalendarGroup } from 'owa-graph-schema';
import type CalendarGroupType from 'owa-service/lib/contract/CalendarGroupType';
import type { MailboxType } from 'owa-client-types';
import { getCalendarGroups } from './calendarsCacheSelectors';
import { getIndexerForConnectedAccount } from 'owa-accounts-store';
import { isSameCoprincipalAccountMailboxAndIndexer } from 'owa-client-types';

/**
 * Gets sorted calendar groups for an account
 */
export function getSortedCalendarGroups(account: string): CalendarGroup[] {
    // filter and sort group for account
    return getCalendarGroups()
        .filter(group => isGroupInAccount(group, account))
        .sort(compareGroupsByGroupType);
}

function isGroupInAccount(group: CalendarGroup, indexerOrUserIdentity: string): boolean {
    const indexer = getIndexerForConnectedAccount(indexerOrUserIdentity);
    return isSameCoprincipalAccountMailboxAndIndexer(group?.calendarGroupId.mailboxInfo, indexer);
}

function compareGroupsByGroupType(a: CalendarGroup, b: CalendarGroup) {
    // 1. compare by group type
    const groupTypeDiff =
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2538 (26,35): Type 'null' cannot be used as an index type.
        // @ts-expect-error
        CalendarGroupTypeOrdering[a.GroupType] - CalendarGroupTypeOrdering[b.GroupType];
    if (groupTypeDiff !== 0) {
        return groupTypeDiff;
    }

    // 2. compare by mailbox type
    const groupMailboxTypeDiff =
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS7053 (36,9): Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ TeamsMailbox: number; UserMailbox: number; GroupMailbox: number; PublicMailbox: number; SharedMailbox: number; ArchiveMailbox: number; }'.
        // @ts-expect-error
        CalendarGroupMailboxTypeOrdering[a.calendarGroupId.mailboxInfo.type] -
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS7053 (40,9): Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ TeamsMailbox: number; UserMailbox: number; GroupMailbox: number; PublicMailbox: number; SharedMailbox: number; ArchiveMailbox: number; }'.
        // @ts-expect-error
        CalendarGroupMailboxTypeOrdering[b.calendarGroupId.mailboxInfo.type];
    if (groupMailboxTypeDiff !== 0) {
        return groupMailboxTypeDiff;
    }

    // 3. tie-break on alphabetical order
    return a.GroupName.localeCompare(b.GroupName);
}

/** Lookup map for sorting calendar groups by group type */
const CalendarGroupTypeOrdering: {
    [key in CalendarGroupType]: number;
} = {
    [0]: 0,
    [2]: 1,
    [1]: 2,
    [3]: 3,
};

/** Lookup map for sorting calendar groups by mailbox type */
const CalendarGroupMailboxTypeOrdering: {
    [key in MailboxType]: number;
} = {
    ['UserMailbox']: 0,
    ['TeamsMailbox']: 1,
    ['GroupMailbox']: 2,
    ['PublicMailbox']: 3,
    ['SharedMailbox']: 4,
    ['ArchiveMailbox']: 5,
    ['PstFile']: 6,
    ['UserPrincipalName']: 7,
};
